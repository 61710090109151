import React, { useState, useEffect } from 'react';
import {Modal, Button } from 'react-bootstrap';
import './Homeasociado.css';
import axios from 'axios';
import Certificado from '../vistas/Certificado';
import moment from 'moment';
//import Card from '../componen/Card';
import Card from 'react-bootstrap/Card';
import logo1 from './images/certificado.jpg';
import logo2 from './images/seguridad.jpg';
import { useAuthcontes } from "../contexts/Authcontes";
window.convertir=require('numero-a-letras');
var estadox = true
var hrefok = 'https://www.aportesenlinea.com/Autoservicio/CertificadoAportes.aspx'
var tipopersonalx = 1
var nombreAsociado = ''
var documentoAsociado = ''
var municipioAsociado = ''
var cargoAsociado = ''
var hospitalAsociado = ''
var diaAsociado = 0
var mesAsociado = 0
var anoAsociado = 0
var diaAsociadoa = 0
var mesAsociadoa = 0
var anoAsociadoa = 0
var valorletraAsociado = ''
var bonificacionAsociado = ''
var valorFormateado =  ''
var bonificacionFormateado =  ''
var valordezplamientoFormateado =  ''
var datosx = [];


    



const Homeasociado = () => {
  const {idempleado}  = useAuthcontes();
  const {tipoEmpleado}  = useAuthcontes();
  tipopersonalx = tipoEmpleado
  //Fin  Funcion Numero a Letras
  function Unidades(num) {

    switch (num) {
        case 1:
            return 'UN';
        case 2:
            return 'DOS';
        case 3:
            return 'TRES';
        case 4:
            return 'CUATRO';
        case 5:
            return 'CINCO';
        case 6:
            return 'SEIS';
        case 7:
            return 'SIETE';
        case 8:
            return 'OCHO';
        case 9:
            return 'NUEVE';
        default:
          return '';
              
    }

} //Unidades()

function Decenas(num) {

  let decena = Math.floor(num / 10);
  let unidad = num - (decena * 10);

  switch (decena) {
      case 1:
          switch (unidad) {
              case 0:
                  return 'DIEZ';
              case 1:
                  return 'ONCE';
              case 2:
                  return 'DOCE';
              case 3:
                  return 'TRECE';
              case 4:
                  return 'CATORCE';
              case 5:
                  return 'QUINCE';
              default:
                  return 'DIECI' + Unidades(unidad);
          }
      case 2:
          switch (unidad) {
              case 0:
                  return 'VEINTE';
              default:
                  return 'VEINTI' + Unidades(unidad);
          }
      case 3:
          return DecenasY('TREINTA', unidad);
      case 4:
          return DecenasY('CUARENTA', unidad);
      case 5:
          return DecenasY('CINCUENTA', unidad);
      case 6:
          return DecenasY('SESENTA', unidad);
      case 7:
          return DecenasY('SETENTA', unidad);
      case 8:
          return DecenasY('OCHENTA', unidad);
      case 9:
          return DecenasY('NOVENTA', unidad);
      case 0:
          return Unidades(unidad);
      default:
         return Unidades(unidad);    
  }
} //Unidades()

function DecenasY(strSin, numUnidades) {
if (numUnidades > 0)
    return strSin + ' Y ' + Unidades(numUnidades)

return strSin;
} //DecenasY()

function Centenas(num) {
let centenas = Math.floor(num / 100);
let decenas = num - (centenas * 100);

switch (centenas) {
  case 1:
      if (decenas > 0)
          return 'CIENTO ' + Decenas(decenas);
      return 'CIEN';
  case 2:
      return 'DOSCIENTOS ' + Decenas(decenas);
  case 3:
      return 'TRESCIENTOS ' + Decenas(decenas);
  case 4:
      return 'CUATROCIENTOS ' + Decenas(decenas);
  case 5:
      return 'QUINIENTOS ' + Decenas(decenas);
  case 6:
      return 'SEISCIENTOS ' + Decenas(decenas);
  case 7:
      return 'SETECIENTOS ' + Decenas(decenas);
  case 8:
      return 'OCHOCIENTOS ' + Decenas(decenas);
  case 9:
      return 'NOVECIENTOS ' + Decenas(decenas);
  default:
    return Decenas(decenas);    
}

} //Centenas()

function Seccion(num, divisor, strSingular, strPlural) {
let cientos = Math.floor(num / divisor)
let resto = num - (cientos * divisor)

let letras = '';

if (cientos > 0)
  if (cientos > 1)
      letras = Centenas(cientos) + ' ' + strPlural;
  else
      letras = strSingular;

if (resto > 0)
  letras += '';

return letras;
} //Seccion()

function Miles(num) {
let divisor = 1000;
let cientos = Math.floor(num / divisor)
let resto = num - (cientos * divisor)

let strMiles = Seccion(num, divisor, 'UN MIL', 'MIL');
let strCentenas = Centenas(resto);

if (strMiles === '')
  return strCentenas;

return strMiles + ' ' + strCentenas;
} //Miles()

function Millones(num) {
let divisor = 1000000;
let cientos = Math.floor(num / divisor)
let resto = num - (cientos * divisor)

let strMillones = Seccion(num, divisor, 'UN MILLON ', 'MILLONES');
let strMiles = Miles(resto);

if (strMillones === '')
  return strMiles;

return strMillones + ' ' + strMiles;
} //Millones()


function NumeroALetras(num, currency) {
currency = currency || {};
let data = {
    numero: num,
    enteros: Math.floor(num),
    centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
    letrasCentavos: '',
    letrasMonedaPlural: currency.plural || 'PESOS', //'PESOS', 'Dólares', 'Bolívares', 'etcs'
    letrasMonedaSingular: currency.singular || 'PESO', //'PESO', 'Dólar', 'Bolivar', 'etc'
    letrasMonedaCentavoPlural: currency.centPlural || 'CHIQUI PESOS',
    letrasMonedaCentavoSingular: currency.centSingular || 'CHIQUI PESO'
};

if (data.centavos > 0) {
    data.letrasCentavos = 'CON ' + (function() {
        if (data.centavos === 1)
            return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular;
        else
            return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
    })();
};

if (data.enteros === 0)
    return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
if (data.enteros === 1)
    return Millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;
else
    return Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
};


  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  function elmes(num){    
    switch(num)
    {
        case 1: return 'Enero ';
        case 2: return 'Febrero ';
        case 3: return 'Marzo ';
        case 4: return 'Abril ';
        case 5: return 'Mayo ';
        case 6: return 'Junio ';
        case 7: return 'Julio ';
        case 8: return 'Agosto ';
        case 9: return 'Septiembre ';
        case 10: return 'Septiembre ';
        case 11: return 'Octubre ';
        case 12: return 'Noviembre';
        default: return '';
      }
    }

  const peticionGet=async()=>{
    const baseUrl2="https://soul-group.net/res_prueba/post.php?id_empleado="+String(idempleado);
    var URFINAL = baseUrl2;
    console.log(URFINAL)
    await axios.get(URFINAL)
    
    .then(response=>{
        console.log('ingrese ok')
        console.log(URFINAL)
           console.log(response.data)
           datosx = response.data;
           nombreAsociado = datosx.nombre;
           documentoAsociado = datosx.documento //ok
           municipioAsociado = datosx.municipio //ok
           cargoAsociado = datosx.cargo //ok
           hospitalAsociado = datosx.hospital
           estadox = datosx.estado
           diaAsociado = moment(datosx.fecha).date();
           mesAsociado =  elmes(moment(datosx.fecha).month()+1);
           anoAsociado =  moment(datosx.fecha).year();
           diaAsociadoa = moment(datosx.fecha_inactivo).date();
           mesAsociadoa =  elmes(moment(datosx.fecha_inactivo).month());
           anoAsociadoa =  moment(datosx.fecha_inactivo).year();
           valorletraAsociado = NumeroALetras(datosx.valor) //ok
           bonificacionAsociado = NumeroALetras(datosx.bonificacion) //ok               
           valorFormateado =  formatter.format(datosx.valor);
           bonificacionFormateado =   formatter.format(datosx.bonificacion);
           if(datosx.dezplamiento === 0){
            valordezplamientoFormateado =  'y un auxilio de desplazamiento de '+NumeroALetras(datosx.dezplamiento)+'('+formatter.format(datosx.dezplamiento)+')';
           } else {
            valordezplamientoFormateado =  '.'
           }
           console.log('Personal: '+tipoEmpleado)
           if(tipopersonalx === 1){
                hrefok = 'https://www.aportesenlinea.com/Autoservicio/CertificadoAportes.aspx'
           } else {
                 hrefok = 'https://www.enlace-apb.com/interssi/descargarCertificacionPago.jsp'
           }

           
    }).catch(error=>{
      console.log(error);
    })
  }


  
  const [visible, setVisible] = useState(false);

  const btnClose = () => setVisible(false);
  const btnShow = () => setVisible(true);

  useEffect(()=>{
    peticionGet();
    },[])
    
  return (
    <div className="containerasociado">
      <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src={logo2} />
      <Card.Body>
        <Card.Title>Cert. de Pago Salud - Asociados</Card.Title>
        <Card.Text>
          Problemas con la atención?
        </Card.Text>
        <Button variant="primary" href={hrefok} target="_blank" >Consultar</Button>
      </Card.Body>
      </Card>
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src={logo1} />
      <Card.Body>
        <Card.Title>Certificado Laboral</Card.Title>
        <Card.Text>
          Certificado en cualquier momento.
        </Card.Text>
        <Button variant="primary" onClick={btnShow}>
         Imprimir Certificado
      </Button>
      </Card.Body>
    </Card>
      <Modal  fullscreen
             aria-labelledby="contained-modal-title-vcenter"
            centered
            show={visible} onHide={btnClose}>
        <Modal.Header closeButton>
          <Modal.Title>Certificado Laboral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <Certificado nombreAsociado={nombreAsociado} documentoAsociado={documentoAsociado} 
                        municipioAsociado={municipioAsociado} cargoAsociado={cargoAsociado} 
                        hospitalAsociado={hospitalAsociado} estadox={estadox} tipopersonalx={tipopersonalx}
                        diaAsociado={diaAsociado} mesAsociado={mesAsociado} anoAsociado={anoAsociado}  diaAsociadoa={diaAsociadoa}
                        mesAsociadoa={mesAsociadoa} anoAsociadoa={anoAsociadoa} valorletraAsociado={valorletraAsociado} bonificacionAsociado={bonificacionAsociado}
                        valorFormateado={valorFormateado} bonificacionFormateado={bonificacionFormateado} valordezplamientoFormateado={valordezplamientoFormateado} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={btnClose}>
            Salir
          </Button>
        </Modal.Footer>
      </Modal>      
   </div>
  )
}


export default Homeasociado



