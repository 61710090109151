import React from 'react'
import { Routes, Route ,Outlet} from "react-router-dom";
import Menu from './Menu'
import './Homeasociado.css';
import Homeasociado from './Homeasociado'
import Footer from './Footer';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { useAuthcontes } from "../contexts/Authcontes";




const Private = () => {
  const {nombreempleadoref}  = useAuthcontes();
  return (
    <>
      <Menu/>
      <Navbar bg="primary">
        <Container>
             <Navbar.Brand >Portal de :: {nombreempleadoref}</Navbar.Brand>
          </Container>
      </Navbar>   
      <Outlet/>
      <Routes>
            <Route index path='/' element={<Homeasociado/>} />
      </Routes>
      <Footer/>
      </>
      )
}
export default Private

