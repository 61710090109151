import React, {useEffect, useState} from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import imagen1 from './images/Imagen1.png';
import firma from './images/firma.jpg';
import moment from 'moment';
import './Menu.css';
import {
  Page,
  Text,
  Image,
  Document,
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { useAuthcontes } from "../contexts/Authcontes";
//var diaImpresion = Date().getDate()
//var mesImpresion = Date().getMonth()
//var anoImpresion = Date().getFullYear()
window.convertir=require('numero-a-letras');
const fecha = new Date();
var diaImpresion = '10'
var estadoA = 1
var mesImpresion = '01'
var anoImpresion = '2022'
var diaAsociado = 0
var mesAsociado = 0
var anoAsociado = 0
var diaAsociadoa = 0
var mesAsociadoa = 0
var anoAsociadoa = 0
var valorletraAsociado = ''
var bonificacionAsociado = ''
var valorFormateado =  ''
var bonificacionFormateado =  ''
var valordezplamientoFormateado =  ''

var estadox = 1
var tipopersonalx = 1
var nombreAsociado = ''
var documentoAsociado = ''
var municipioAsociado = ''
var cargoAsociado = ''
var hospitalAsociado = ''



const util = require('util');




Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  detalle: {
    fontSize: 12,
    textAlign: 'center',
  },

  pie: {
    fontSize: 10,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  image1: {
    marginVertical: 15,
    marginHorizontal: 200,
  },

  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 50,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber2: {
    position: 'absolute',
    fontSize: 8,
    bottom: 40,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber3: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

});


//const Certificado = (nombreAsociado,documentoAsociado,municipioAsociado,cargoAsociado,hospitalAsociado,estadox,tipopersonalx) => {
  const Certificado = (props) => {
    const {datosy} = props
    estadox  = props.estadox
  nombreAsociado = props.nombreAsociado
  documentoAsociado = props.documentoAsociado
  municipioAsociado = props.municipioAsociado
  cargoAsociado = props.cargoAsociado
  hospitalAsociado = props.hospitalAsociado
  diaAsociado = props.diaAsociado
  mesAsociado = props.mesAsociado
  anoAsociado = props.anoAsociado
  diaAsociadoa = props.diaAsociadoa
  mesAsociadoa = props.mesAsociadoa
  anoAsociadoa = props.anoAsociadoa
  valorletraAsociado = props.valorletraAsociado
  bonificacionAsociado = props.bonificacionAsociado
  valorFormateado =  props.valorFormateado
  bonificacionFormateado =  props.tipopersonalx
  valordezplamientoFormateado =  props.valordezplamientoFormateado


  const {idempleado}  = useAuthcontes();
const {estado}  = useAuthcontes();
estadoA =estado
const {tipoEmpleado}  = useAuthcontes();
tipopersonalx = tipoEmpleado


const MyPage = () => (
  <Page size="A4" style={styles.body}>
     <Image style={styles.image} src={imagen1} /> 
     <Text style={styles.text}>Medellín, {diaImpresion} de {mesImpresion} de {anoImpresion}.</Text>
    <Text style={styles.title}>ASOCIACIÓN SINDICAL DE SALUD DE ANTIOQUIA</Text>
    <Text style={styles.title}>SINDISALUD</Text>
    <Text style={styles.author}>CERTIFICA:</Text>
   
    <Text style={styles.text}>
          Que el señor(a) {nombreAsociado} identificado(a) con Cédula de Ciudadanía Nro. {documentoAsociado} residente en el Municipio de {municipioAsociado} - Antioquia, es Asociado activo de SINDISALUD. 
          En su condición de afiliado(a) partícipe sin subordinación se desempeña como {cargoAsociado} en cumplimiento de los diferentes contratos sindicales suscritos con la 
          E.S.E Hospital {hospitalAsociado}, desde el día {diaAsociado} de {mesAsociado} de {anoAsociado}.
    </Text>
    <Text style={styles.text}>
        Actualmente percibe una compensación mensual básica de {valorletraAsociado} ({valorFormateado})  , 
        una bonificación de {bonificacionAsociado} ({bonificacionFormateado}){valordezplamientoFormateado}
    </Text>
    <Text style={styles.subtitle}>
        Se expide este certificado a solicitud del interesado(a) y dirigido a: QUIEN PUEDA INTERESAR. 
   </Text>
   <Image style={styles.image1} src={firma} /> 
   <Text style={styles.detalle}>Marco Antonio Herrera Suárez</Text>
   <Text style={styles.detalle}>Vicepresidente - SINDISALUD</Text>
   <Text style={styles.pageNumber}>Apoyando La Salud y El Bienestar…  </Text>
   <Text style={styles.pageNumber2}>Carrera 80 No. 46 – 34 Segundo Piso, La Floresta, Medellín</Text>
   <Text style={styles.pageNumber3}>Tel: 604-4018637</Text>
  </Page>
);
const MyPagePa = () => (
  <Page size="A4" style={styles.body}>
     <Image style={styles.image} src={imagen1} /> 
     <Text style={styles.text}>Medellín, {diaImpresion} de {mesImpresion} de {anoImpresion}.</Text>
    <Text style={styles.title}>ASOCIACIÓN SINDICAL DE SALUD DE ANTIOQUIA</Text>
    <Text style={styles.title}>SINDISALUD</Text>
    <Text style={styles.author}>CERTIFICA:</Text>
   
    <Text style={styles.text}>
          Que el señor(a) {nombreAsociado} identificado(a) con Cédula de Ciudadanía Nro. {documentoAsociado} residente en el Municipio de {municipioAsociado} - Antioquia.
          En su condición de trabajador independiente se desempeña como {cargoAsociado} bajo un contrato de prestación de servicios en cumplimiento de los diferentes
          contratos sindicales suscritos con la {hospitalAsociado}, desde el día {diaAsociado} de {mesAsociado} de {anoAsociado}.
    </Text>
    <Text style={styles.text}>
      Que actualmente percibe unos honorarios calculados según la sumatoria del total de horas realmente laboradas durante el mes,
      pero se estipula como base la suma de {valorletraAsociado} ({valorFormateado}),valor sujeto a lo realmente laborado por el contratista en el mes
    </Text>
    <Text style={styles.subtitle}>
        Se expide este certificado a solicitud del interesado(a) y dirigido a: QUIEN PUEDA INTERESAR. 
   </Text>
   <Image style={styles.imagen1} src={firma} /> 
   <Text style={styles.detalle}>Marco Antonio Herrera Suárez</Text>
   <Text style={styles.detalle}>Vicepresidente - SINDISALUD</Text>
   <Text style={styles.pageNumber}>Apoyando La Salud y El Bienestar…  </Text>
   <Text style={styles.pageNumber2}>Carrera 80 No. 46 – 34 Segundo Piso, La Floresta, Medellín</Text>
   <Text style={styles.pageNumber3}>Tel: 604-4018637</Text>
  </Page>
);
const MyPageEa = () => (
  <Page size="A4" style={styles.body}>
     <Image style={styles.image} src={imagen1} /> 
     <Text style={styles.text}>Medellín, {diaImpresion} de {mesImpresion} de {anoImpresion}.</Text>
    <Text style={styles.title}>ASOCIACIÓN SINDICAL DE SALUD DE ANTIOQUIA</Text>
    <Text style={styles.title}>SINDISALUD</Text>
    <Text style={styles.author}>CERTIFICA:</Text>
   
    <Text style={styles.text}>
          Que el señor(a) {nombreAsociado} identificado(a) con Cédula de Ciudadanía Nro. {documentoAsociado} residente en el Municipio de {municipioAsociado} - Antioquia, es Empleado de SINDISALUD. 
          En su condición de trabajador, se desempeña actualmente como {cargoAsociado} , bajo un contrato a termino indefinido, 
          con un salario básico mensual de  {valorletraAsociado} ({valorFormateado}) , desde el día {diaAsociado} de {mesAsociado} de {anoAsociado}.
    </Text>
    
    <Text style={styles.subtitle}>
        Se expide este certificado a solicitud del interesado(a) y dirigido a: QUIEN PUEDA INTERESAR. 
   </Text>
   <Image style={styles.image1} src={firma} /> 
   <Text style={styles.detalle}>Marco Antonio Herrera Suárez</Text>
   <Text style={styles.detalle}>Vicepresidente - SINDISALUD</Text>
   <Text style={styles.pageNumber}>Apoyando La Salud y El Bienestar…  </Text>
   <Text style={styles.pageNumber2}>Carrera 80 No. 46 – 34 Segundo Piso, La Floresta, Medellín</Text>
   <Text style={styles.pageNumber3}>Tel: 604-4018637</Text>
  </Page>
);
const MyPage1 = () => (
  <Page size="A4" style={styles.body}>
     <Image style={styles.image} src={imagen1} /> 
     <Text style={styles.text}>Medellín, {diaImpresion} de {mesImpresion} de {anoImpresion}.</Text>
    <Text style={styles.title}>ASOCIACIÓN SINDICAL DE SALUD DE ANTIOQUIA</Text>
    <Text style={styles.title}>SINDISALUD</Text>
    <Text style={styles.author}>CERTIFICA:</Text>
   
    <Text style={styles.text}>
          Que el señor(a) {nombreAsociado} identificado(a) con Cédula de Ciudadanía Nro. {documentoAsociado} residente en el Municipio de {municipioAsociado} - Antioquia, fue Asociado de SINDISALUD. 
          En su condición de afiliado(a) partícipe sin subordinación se desempeñaba como {cargoAsociado} en cumplimiento de los diferentes contratos sindicales suscritos con la 
          E.S.E Hospital {hospitalAsociado}, desde el día {diaAsociado} de {mesAsociado} de {anoAsociado}, hasta el día {diaAsociadoa} de {mesAsociadoa} de {anoAsociadoa}.
    </Text>
    <Text style={styles.subtitle}>
        Se expide este certificado a solicitud del interesado(a) y dirigido a: QUIEN PUEDA INTERESAR. 
   </Text>
   <Image style={styles.image1} src={firma} /> 
   <Text style={styles.detalle}>Marco Antonio Herrera Suárez</Text>
   <Text style={styles.detalle}>Vicepresidente - SINDISALUD</Text>
   <Text style={styles.pageNumber}>Apoyando La Salud y El Bienestar…  </Text>
   <Text style={styles.pageNumber2}>Carrera 80 No. 46 – 34 Segundo Piso, La Floresta, Medellín</Text>
   <Text style={styles.pageNumber3}>Tel: 604-4018637</Text>
  </Page>
);
const MyPagePi = () => (
  <Page size="A4" style={styles.body}>
     <Image style={styles.image1} src={imagen1} /> 
     <Text style={styles.text}>Medellín, {diaImpresion} de {mesImpresion} de {anoImpresion}.</Text>
    <Text style={styles.title}>ASOCIACIÓN SINDICAL DE SALUD DE ANTIOQUIA</Text>
    <Text style={styles.title}>SINDISALUD</Text>
    <Text style={styles.author}>CERTIFICA:</Text>
   
    <Text style={styles.text}>
          Que el señor(a) {nombreAsociado} identificado(a) con Cédula de Ciudadanía Nro. {documentoAsociado} residente en el Municipio de {municipioAsociado} - Antioquia. 
          En su condición de trabajador independiente, se desempeñó en la asociación como {cargoAsociado},bajo un contrato de prestación de servicios en cumplimiento
          de los diferentes contratos sindicales suscritos con la {hospitalAsociado}, desde el día {diaAsociado} de {mesAsociado} de {anoAsociado}, hasta el día {diaAsociadoa} de {mesAsociadoa} de {anoAsociadoa}.
    </Text>
    <Text style={styles.subtitle}>
        Se expide este certificado a solicitud del interesado(a) y dirigido a: QUIEN PUEDA INTERESAR. 
   </Text>
   <Image style={styles.image1} src={firma} /> 
   <Text style={styles.detalle}>Marco Antonio Herrera Suárez</Text>
   <Text style={styles.detalle}>Vicepresidente - SINDISALUD</Text>
   <Text style={styles.pageNumber}>Apoyando La Salud y El Bienestar…  </Text>
   <Text style={styles.pageNumber2}>Carrera 80 No. 46 – 34 Segundo Piso, La Floresta, Medellín</Text>
   <Text style={styles.pageNumber3}>Tel: 604-4018637</Text>
  </Page>
);
const MyPageEi = () => (
  <Page size="A4" style={styles.body}>
     <Image style={styles.image} src={imagen1} /> 
     <Text style={styles.text}>Medellín, {diaImpresion} de {mesImpresion} de {anoImpresion}.</Text>
    <Text style={styles.title}>ASOCIACIÓN SINDICAL DE SALUD DE ANTIOQUIA</Text>
    <Text style={styles.title}>SINDISALUD</Text>
    <Text style={styles.author}>CERTIFICA:</Text>
   
    <Text style={styles.text}>
          Que el señor(a) {nombreAsociado} identificado(a) con Cédula de Ciudadanía Nro. {documentoAsociado} residente en el Municipio de {municipioAsociado} - Antioquia.
          En su condición de trabajador, se desempeñó en la asociación como {cargoAsociado}. desde el día {diaAsociado} de {mesAsociado} de {anoAsociado}, hasta el día {diaAsociadoa} de {mesAsociadoa} de {anoAsociadoa}.
    </Text>
    <Text style={styles.subtitle}>
        Se expide este certificado a solicitud del interesado(a) y dirigido a: QUIEN PUEDA INTERESAR. 
   </Text>
   <Image style={styles.image1} src={firma} /> 
   <Text style={styles.detalle}>Marco Antonio Herrera Suárez</Text>
   <Text style={styles.detalle}>Vicepresidente - SINDISALUD</Text>
   <Text style={styles.pageNumber}>Apoyando La Salud y El Bienestar…  </Text>
   <Text style={styles.pageNumber2}>Carrera 80 No. 46 – 34 Segundo Piso, La Floresta, Medellín</Text>
   <Text style={styles.pageNumber3}>Tel: 604-4018637</Text>
  </Page>
);



const MyDoc = () => {
  var EstadoYY = estado
  console.log('Estado en MyDoc es'+EstadoYY)
  var TipoempleadoXX = String(tipopersonalx)
  console.log('Tipo  en MyDoc es '+TipoempleadoXX)
   if (EstadoYY== 1) {
    switch (TipoempleadoXX) {
        case '1': 
         console.log('case 1-'+TipoempleadoXX)
          return (<Document><MyPageEa /></Document>);
          break;
        case '2': 
        console.log('case 2-'+TipoempleadoXX)
          return (<Document><MyPagePa /></Document>);
          break;
        case '5':
          console.log('case 5-'+TipoempleadoXX)
          return (<Document><MyPage /></Document>);  
          break;        
        default:
          console.log('case defa-'+TipoempleadoXX)
          return (<Document><MyPage /></Document>);
          break;
      }
    }
      else {
        switch (TipoempleadoXX) {
          case '1': 
            return (<Document><MyPageEi /></Document>);
          case '2': 
            return (<Document><MyPagePi /></Document>);
          case '5':
              return (<Document><MyPage1 /></Document>);            
          default:
            return (<Document><MyPage1 /></Document>);
        }

      }
};

  function elmes(num){    
  switch(num)
  {
      case 1: return 'Enero ';
      case 2: return 'Febrero ';
      case 3: return 'Marzo ';
      case 4: return 'Abril ';
      case 5: return 'Mayo ';
      case 6: return 'Junio ';
      case 7: return 'Julio ';
      case 8: return 'Agosto ';
      case 9: return 'Septiembre ';
      case 10: return 'Septiembre ';
      case 11: return 'Octubre ';
      case 12: return 'Noviembre';
      default: return '';
    }
    
      
  }

      diaImpresion = fecha.getDate()
      mesImpresion = elmes(fecha.getMonth()+1)
      anoImpresion = fecha.getFullYear()
      function Unidades(num) {

        switch (num) {
            case 1:
                return 'UN';
            case 2:
                return 'DOS';
            case 3:
                return 'TRES';
            case 4:
                return 'CUATRO';
            case 5:
                return 'CINCO';
            case 6:
                return 'SEIS';
            case 7:
                return 'SIETE';
            case 8:
                return 'OCHO';
            case 9:
                return 'NUEVE';
            default:
              return '';
                  
        }

    } //Unidades()
    
    function Decenas(num) {

      let decena = Math.floor(num / 10);
      let unidad = num - (decena * 10);

      switch (decena) {
          case 1:
              switch (unidad) {
                  case 0:
                      return 'DIEZ';
                  case 1:
                      return 'ONCE';
                  case 2:
                      return 'DOCE';
                  case 3:
                      return 'TRECE';
                  case 4:
                      return 'CATORCE';
                  case 5:
                      return 'QUINCE';
                  default:
                      return 'DIECI' + Unidades(unidad);
              }
          case 2:
              switch (unidad) {
                  case 0:
                      return 'VEINTE';
                  default:
                      return 'VEINTI' + Unidades(unidad);
              }
          case 3:
              return DecenasY('TREINTA', unidad);
          case 4:
              return DecenasY('CUARENTA', unidad);
          case 5:
              return DecenasY('CINCUENTA', unidad);
          case 6:
              return DecenasY('SESENTA', unidad);
          case 7:
              return DecenasY('SETENTA', unidad);
          case 8:
              return DecenasY('OCHENTA', unidad);
          case 9:
              return DecenasY('NOVENTA', unidad);
          case 0:
              return Unidades(unidad);
          default:
             return Unidades(unidad);    
      }
  } //Unidades()
    
  function DecenasY(strSin, numUnidades) {
    if (numUnidades > 0)
        return strSin + ' Y ' + Unidades(numUnidades)

    return strSin;
} //DecenasY()
    
function Centenas(num) {
  let centenas = Math.floor(num / 100);
  let decenas = num - (centenas * 100);

  switch (centenas) {
      case 1:
          if (decenas > 0)
              return 'CIENTO ' + Decenas(decenas);
          return 'CIEN';
      case 2:
          return 'DOSCIENTOS ' + Decenas(decenas);
      case 3:
          return 'TRESCIENTOS ' + Decenas(decenas);
      case 4:
          return 'CUATROCIENTOS ' + Decenas(decenas);
      case 5:
          return 'QUINIENTOS ' + Decenas(decenas);
      case 6:
          return 'SEISCIENTOS ' + Decenas(decenas);
      case 7:
          return 'SETECIENTOS ' + Decenas(decenas);
      case 8:
          return 'OCHOCIENTOS ' + Decenas(decenas);
      case 9:
          return 'NOVECIENTOS ' + Decenas(decenas);
      default:
        return Decenas(decenas);    
  }

} //Centenas()
    
function Seccion(num, divisor, strSingular, strPlural) {
  let cientos = Math.floor(num / divisor)
  let resto = num - (cientos * divisor)

  let letras = '';

  if (cientos > 0)
      if (cientos > 1)
          letras = Centenas(cientos) + ' ' + strPlural;
      else
          letras = strSingular;

  if (resto > 0)
      letras += '';

  return letras;
} //Seccion()
    
function Miles(num) {
  let divisor = 1000;
  let cientos = Math.floor(num / divisor)
  let resto = num - (cientos * divisor)

  let strMiles = Seccion(num, divisor, 'UN MIL', 'MIL');
  let strCentenas = Centenas(resto);

  if (strMiles === '')
      return strCentenas;

  return strMiles + ' ' + strCentenas;
} //Miles()
    
function Millones(num) {
  let divisor = 1000000;
  let cientos = Math.floor(num / divisor)
  let resto = num - (cientos * divisor)

  let strMillones = Seccion(num, divisor, 'UN MILLON ', 'MILLONES');
  let strMiles = Miles(resto);

  if (strMillones === '')
      return strMiles;

  return strMillones + ' ' + strMiles;
} //Millones()
    

  function NumeroALetras(num, currency) {
    currency = currency || {};
    let data = {
        numero: num,
        enteros: Math.floor(num),
        centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
        letrasCentavos: '',
        letrasMonedaPlural: currency.plural || 'PESOS', //'PESOS', 'Dólares', 'Bolívares', 'etcs'
        letrasMonedaSingular: currency.singular || 'PESO', //'PESO', 'Dólar', 'Bolivar', 'etc'
        letrasMonedaCentavoPlural: currency.centPlural || 'CHIQUI PESOS',
        letrasMonedaCentavoSingular: currency.centSingular || 'CHIQUI PESO'
    };

    if (data.centavos > 0) {
        data.letrasCentavos = 'CON ' + (function() {
            if (data.centavos === 1)
                return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular;
            else
                return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
        })();
    };

    if (data.enteros === 0)
        return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
    if (data.enteros === 1)
        return Millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;
    else
        return Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
};
      ///Fin  Funcion Numero a Letras
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      
      
      
  

      //const [data, setData]=useState([]);

      
      
    

      useEffect(()=>{
        //peticionGet();
        },[])

      return (
        <div className="certificado">
        <Button variant="warning">
            <PDFDownloadLink document={<MyDoc />} fileName="Certificado.pdf">
            {({ blob, url, loading, error }) =>
                loading ? 'Cargando la colilla' : ''
            }
            Bajarlo
            </PDFDownloadLink>
            </Button>
            <PDFViewer style={{ width: '1200px', height: '800px' }}>
               <MyDoc />
            </PDFViewer>
            </div>
        
);}

//render(<Certificado />, document.getElementById('root'));

export default Certificado;

