import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Login from './vistas/Login';
import Private from './vistas/Private';
import Logaut from './vistas/Logaut';
import Colillas from './vistas/Colillas';
import Cambioclave from './vistas/Cambioclave';
import Certificado from './vistas/Certificado';
import Colilla from './vistas/Colilla';
import NotfounPage from './vistas/NotFounPage';
import './App.css';
import {AuthcontesProvider } from './contexts/Authcontes';

function App() {
  return (
    <AuthcontesProvider>
      <BrowserRouter basename="/">
        <Routes>
          <Route index path='/login' element={<Login/>} />
          <Route path='/private' element={<Private/>} />
          <Route path='/logaut' element={<Logaut/>} />
          <Route path='/private/*' element={<Private/>} >
             <Route path='colillas' element={<Colillas/>} />
             <Route path='cambioclave' element={<Cambioclave />} />
             <Route path='certificado' element={<Certificado />} />
             <Route path='colilla' element={<Colilla />} />
          </Route>
          <Route exact path='/'  element={<Login/>}/>
          <Route path='*' element={<Login />}/>
          
        </Routes>
      </BrowserRouter>
    </AuthcontesProvider>
  );
}

export default App;
