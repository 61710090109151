import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from  'prop-types'
const MI_AUT_APP = 'MI_AUT_APP1'

export const Authcontes = createContext();

export function AuthcontesProvider({children}){
    const [isAutentic, Setisautentic] = useState(window.localStorage.getItem(MI_AUT_APP) ?? false) ;
    const [idempleado, Setidempleado] = useState(0);
    const [estado, SetEstado] = useState(1);
    const [tipoEmpleado, SetTipoempleado] = useState(1);
    const [nombreempleadoref, SetNombreempleado] = useState('');

    const login = useCallback(function (xdocumentox,xnombrex,xestadox,xtipoempleadox) {
        window.localStorage.setItem(MI_AUT_APP,true);
        console.log('El Nombre es:'+xnombrex)
        Setidempleado(xdocumentox);
        SetNombreempleado(xnombrex);
        SetEstado(xestadox);
        SetTipoempleado(xtipoempleadox);
        Setisautentic(true);
        console.log('El estado en aute es:'+xestadox)
        console.log('El Tipo en aute es:'+xtipoempleadox)

    },[]);

    
    const logautok = useCallback( function () {
        window.localStorage.removeItem(MI_AUT_APP)
        console.log('Desde el Auto')
        Setisautentic(false);
        Setidempleado(0);
        SetEstado(1);
        SetTipoempleado(1)
        //navigate('/login')
    },[]);

    const valores = useMemo(
        ()=>({
        login,
        logautok,
        idempleado,
        nombreempleadoref,
        estado,
        tipoEmpleado,
        isAutentic
    }),
       [login , logautok , idempleado , nombreempleadoref , estado, tipoEmpleado , isAutentic ]
       );

    return <Authcontes.Provider value={valores}>{children}</Authcontes.Provider>
}

AuthcontesProvider.propTypes= {
    children : PropTypes.object
};

export function useAuthcontes(){
    return useContext(Authcontes);
}