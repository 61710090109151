import React from "react";

const style = {
  backgroundColor: "#282c34",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  color: "#F0DFDC",
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "60px",
  width: "100%"
};

export default function Footer() {
  return (
    <div>
      <div style={style}>by SINDISALUD</div>
    </div>
  );
}