import React, {useState, useEffect} from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { useAuthcontes } from "../contexts/Authcontes";
import { Page, Text, Document, Image, PDFViewer, View, StyleSheet, Font,  PDFDownloadLink} from '@react-pdf/renderer';
import imagen1 from './images/sindisaludlogocolilla.png';
var documentox = ''
var nombre = ''
var cargo = ''
var nomina = ''
var basico = ''
var auxilio = ''
var cuenta = ''
var hospitalAsociado = ''
const borderColor = '#90e5fc'

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 5,
    paddingBottom: 0,
    paddingHorizontal:0,
  },
  title: {
    fontSize: 20,
    fontFamily: 'Oswald',
    textAlign: 'right'
  },
  author: {
    fontSize: 10,
    textAlign: 'center',
    marginBottom: 1,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 5,
    marginHorizontal: 5,
    width: 200,
    height: 80,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  section: {
    margin: 5,
    padding: 5,
    flexDirection: 'row'
  },
  section1: {
   paddingTop: 0,
    padding: 0,
    flexDirection: 'row'
  },
  sectionxx: {
    paddingTop: 0,
    padding: 0,
    flexDirection: 'row'
   },
  section2: {
    margin: 10,
    padding: 10,
    textAlign: 'right',
    flexDirection: 'column'
  },
  section3: {
    margin: 0,
    paddingTop: 1,
    flexDirection: 'row'
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
},
nombrer: {
    width: '45%',
    fontSize: 10,
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 1,
},
basex: {
    width: '10%',
    fontSize: 10,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
},
deduccionx: {
    width: '20%',
    fontSize: 10,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
},
devengadox: {
    width: '20%',
    fontSize: 10,
    textAlign: 'right',
    paddingRight: 8,
},
descriptionxx: {
  width: '75%',
  textAlign: 'right',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingRight: 8,
},
totalxx: {
  textAlign: 'right',
  paddingRight: 8,
  width: '20%',
  fontSize: 12,
},
sectionx: {
  width: 200,
  '@media max-width: 400': {
    width: 400,
  },
  '@media orientation: landscape': {
    width: 400,
  },
},
containerh: {
  flexDirection: 'row',
  borderBottomColor: '#bff0fd',
  backgroundColor: '#bff0fd',
  borderBottomWidth: 1,
  alignItems: 'center',
  height: 24,
  textAlign: 'center',
  fontStyle: 'bold',
},
nombre: {
  width: '45%',
  fontSize: 10,
  borderRightColor: borderColor,
  borderRightWidth: 1,
},
base: {
  width: '10%',
  fontSize: 10,
  borderRightColor: borderColor,
  borderRightWidth: 1,
},
deduccion: {
  width: '20%',
  fontSize: 10,
  borderRightColor: borderColor,
  borderRightWidth: 1,
},
devengado: {
  width: '20%',
  fontSize: 10,
},
subtotal: {
  width: '55%',
  textAlign: 'right',
  fontSize: 10,
  borderRightColor: borderColor,
  borderRightWidth: 1,
},
deduccionesxx: {
  width: '20%',
  textAlign: 'right',
  fontSize: 10,
  borderRightColor: borderColor,
  borderRightWidth: 1,
},
devengadosxx: {
  width: '20%',
  textAlign: 'right',
  fontSize: 10,
  borderRightColor: borderColor,
  borderRightWidth: 1,
},

});


const Colilla = (props) => {
  ///
  const [liquidacion, setLiquidacion]=useState([]);
  const devengados = props.devengados
  const deducciones = props.deducciones
  const pagos = props.pagos
  const periodo = props.periodo
  
  const MyPage = () => (
 
    <Page size="A5" style={styles.page}>
     
     <View style={styles.section}>
           <View style={styles.section1}>
             <Image style={styles.image} src={imagen1} /> 
           </View> 
           <View style={styles.section2}>
               <Text style={styles.title}>SINDISALUD</Text>
               <Text style={styles.author}>COLILLA COMPROBANTE DE PAGO</Text>         
               <Text style={styles.author}>PERIODO:{periodo} </Text>
               <Text style={styles.author}>Centro de Trabajo: {nomina}</Text>
           </View>
     </View>
     <View style={styles.sectionxx}>
           <View style={styles.sectionxx}>
             <Text style={styles.author}>Contrato: {documentox}</Text>
           </View> 
           <View style={styles.sectionxx}>
              <Text style={styles.author}>Documento: {documentox}</Text>
           </View>
     </View>
     <View style={styles.sectionxx}>
           <View style={styles.section2}>
             <Text style={styles.author}>Nombre: {nombre} </Text>
           </View> 
           <View style={styles.section2}>
             <Text style={styles.author}>Cargo: {cargo}</Text>
           </View>
     </View>
     <View style={styles.sectionxx}>
           <View style={styles.section}>
             <Text style={styles.author}>Compensacion: {basico}</Text>
           </View> 
           <View style={styles.section}>
              <Text style={styles.author}>Auxilio:{auxilio}</Text>
           </View>
           <View style={styles.section}>
              <Text style={styles.author}>Cuenta Bancaria:{cuenta}</Text>
           </View>
     </View>
     <View style={styles.containerh}>
        <Text style={styles.nombre}>Concepto</Text>
        <Text style={styles.base}>Base</Text>
        <Text style={styles.deduccion}>Deduccion</Text>
        <Text style={styles.devengado}>Devengado</Text>
    </View>
     {liquidacion.map( colillax => (
            <View style={styles.row} key={colillax.id_concepto}>
              <Text style={styles.nombrer}>{colillax.nombre}</Text>
              <Text style={styles.basex}>{colillax.variable}</Text>
              {(colillax.clase == 2) ? <Text style={styles.deduccionx}>{formatter.format(Math.abs(colillax.valor))}</Text> : <Text style={styles.deduccionx}>0</Text>}
              {(colillax.clase == 1) ? <Text style={styles.devengadox}>{formatter.format(colillax.valor)}</Text> : <Text style={styles.devengadox}>0</Text>}
           </View>
          ))}
     <View style={styles.row}>
            <Text style={styles.subtotal}>Subtotal</Text>
            <Text style={styles.deduccionx}>{formatter.format(Math.abs(deducciones))}</Text>
            <Text style={styles.devengadox}>{formatter.format(Math.abs(devengados))}</Text>
        </View>     
        <View style={styles.row}>
            <Text style={styles.descriptionxx}>TOTAL</Text>
            <Text style={styles.devengadox}>{formatter.format(Math.abs(pagos))}</Text>
        </View>     
  
    </Page>
  );
  
  ///

  ////
  const MyDoc = () => (
    <Document>
      <MyPage />    
    </Document>
  );
  ////
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  console.log('LA LIQUIDACION ES LA :'+props.id_liquidacion)
  const {idempleado}  = useAuthcontes();
  var res = [];
  var fetchData = [];
  var data = [];
  const baseUrl2="https://soul-group.net/res_prueba/post.php?id_empleado=";
  const baseUrl3="https://soul-group.net/Api_soul/colilla.php?id_liquidacion=";
  const [datos, setEmpleado]=useState([]);
  const peticionGet=async()=>{
        var URFINAL1 = baseUrl2+String(idempleado);
        var URFINAL2 = baseUrl3+String(props.id_liquidacion);
        console.log(URFINAL1)
        console.log(URFINAL2)
        console.log('Sigue')
        console.log(String(props.deducciones))
        res = await axios.get(URFINAL1)
        .then(response=>{
          setEmpleado(response.data);
          console.log(datos)
          var datosx = response.data;
          documentox = datosx.documento;
          nombre = datosx.nombre //ok
          cargo = datosx.cargo //ok
          nomina = datosx.nomina //ok
          basico = datosx.valor //ok
          auxilio = datosx.bonificacion //ok
          hospitalAsociado = datosx.hospital
          cuenta = datosx.cuenta
          }
          )
        res = await axios.get(URFINAL2)
          .then(response=>{
            setLiquidacion(response.data);
            console.log(liquidacion)
            }
            )
        }
        


  useEffect(()=>{
    peticionGet();
    fetchData = async () => {
      data = await peticionGet();;
  }},[])
      return (
        <div className="certificado">
         <Button variant="warning">
            <PDFDownloadLink document={<MyDoc />} fileName="Colilladepago.pdf">
            {({ blob, url, loading, error }) =>
                loading ? 'Cargando la colilla' : ''
            }
            Bajarla
            </PDFDownloadLink>
            </Button>
            <PDFViewer style={{ width: '1200px', height: '800px' }}>
            <MyDoc />
            </PDFViewer>

            </div>
);}

//render(<Certificado />, document.getElementById('root'));

export default Colilla;

