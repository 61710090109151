import React from 'react'
import { useAuthcontes } from '../contexts/Authcontes';
import { useNavigate } from 'react-router-dom' 
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link} from "react-router-dom";
import './Menu.css';
import logo from './images/logo3.png';



const Menu = () => {
  const navigate = useNavigate()
  const {logautok}  = useAuthcontes();
  const Salirok=()=>{
    console.log('Saliendo') ; 
    logautok();
    navigate('/login')

  }
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
         <Navbar.Brand>
           <img src={logo} alt='logo'/>
        </Navbar.Brand>
        <Navbar.Brand as={Link} to="/private">SINDISALUD::0.2::</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link>
              <Link to='colillas' className="Navink" >COLILLAS</Link>
            </Nav.Link>
            <Nav.Link>
              <Link to='cambioclave' className="Navink" >CAMBIO CLAVE</Link>
            </Nav.Link>

          </Nav>
          <Nav>
            <Button variant="primary" onClick={Salirok}>SALIR</Button>{' '}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default Menu

