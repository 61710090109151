import { useEffect } from "react";
import { useAuthcontes } from "../contexts/Authcontes";

function Logaut(){
    console.log('Entre');
    const {logautok} = useAuthcontes();
    useEffect(()=>logautok());
    return null;
};


export default Logaut;