import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useAuthcontes } from "../contexts/Authcontes";
import Colilla from './Colilla';
import {Modal, Button } from 'react-bootstrap';
var datax = [];

const Colillas = () => {
  
  const baseUrl2="https://soul-group.net/res_prueba/colillas1.php?id_empleado=";
  const [data, setData]=useState([]);
  const {idempleado}  = useAuthcontes();
  const peticionGet=async()=>{
    var URFINAL = baseUrl2+String(idempleado);
    console.log(URFINAL);
    console.log('El id en colillas es '+idempleado);
    await axios.get(URFINAL)
    .then(response=>{
      setData(response.data);
      datax = response.data
      console.log(datax);
    }).catch(error=>{
      console.log(error);
    })
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  useEffect(()=>{
    peticionGet();
  },[])

  const [visible, setVisible] = useState(false);
  const [id_liquidacionx, setLiquidacion] = useState(false);
  const [deduccionesx, setDeduccion] = useState(0);
  const [devengadosx, setDevengados] = useState(0);
  const [pagosx, setPagos] = useState(0);
  const [periodox, setPeriodo] = useState(0);
  const btnClose = () => setVisible(false);
  //const btnShow = () => setVisible(true);
  function btnShow(id_liquidaciony,pagosy,deduccionesy,devengadosy,periodoy){
    setLiquidacion(id_liquidaciony)
    setDevengados(devengadosy)
    setDeduccion(deduccionesy);
    setPagos(pagosy);
    setPeriodo(periodoy);
    setVisible(true);
    setVisible(true);
    console.log('Pasar');
    } 


  return (
    <>
    <div style={{textAlign: 'center'}}>
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Vigencia</th>
          <th>Mes</th>
          <th>Nombre</th>
          <th>Deducciones</th>
          <th>Devengados</th>
          <th>Valor</th>

          <th>Imprimir</th>
        </tr>
      </thead>
      
      <tbody>
      {data.map( colilla => (
          <tr key={colilla.id_liquidacion}>
            <td>{colilla.vigencia}</td>
            <td>{colilla.mes}</td>
            <td>{colilla.descripcion}</td>
            <td>{formatter.format(Math.abs(colilla.deducciones))}</td>
            <td>{formatter.format(Math.abs(colilla.devengados))}</td>
            <td>{formatter.format(Math.abs(colilla.pago))}</td>
            <td>
               <button className="btn btn-primary" onClick={() => btnShow(colilla.id_liquidacion,colilla.pago,colilla.deducciones,colilla.devengados,colilla.descripcion)}>Imprimir</button> 
          </td>
          </tr>
          ))}
      </tbody> 
    </table>
    <Modal  fullscreen
             aria-labelledby="contained-modal-title-vcenter"
            centered
            show={visible} onHide={btnClose}>
        <Modal.Header closeButton>
          <Modal.Title>Colilla de Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Colilla id_liquidacion={id_liquidacionx} devengados={devengadosx} deducciones={deduccionesx} pagos={pagosx} periodo={periodox}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={btnClose}>
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    </>
  );
}


export default Colillas;