import React from 'react'
import { useAuthcontes} from "../contexts/Authcontes";
import { useNavigate } from 'react-router-dom' 
import { useState } from 'react';
import './login.css';
import axios from 'axios';
import { Card,Button } from 'react-bootstrap';
import imagensinsisalud from './images/Imagen1.png';


const Swal = require('sweetalert2')
var data1 = [];
const Login = () => {
const navigate = useNavigate()
const {login}  = useAuthcontes();
const [magicword,Setmagicword] = useState('');
const [codigo,Setcodigo] = useState('');


document.cookie.split(";").forEach(function(c) {
  document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
});

function handleSumiChange(event) {
      Setmagicword(event.target.value);
  }

function handleSumiChangeCodigo(event) {
    Setcodigo(event.target.value);  
 }

 const peticionGet=async()=>{
  console.log('Ingresando')
  //var f = new FormData();
  const baseUrl2="https://soul-group.net/res_prueba/login.php?codigo="+String(codigo);
  var URFINAL = baseUrl2;
  console.log(URFINAL)
  await axios.get(URFINAL)
  //f.append("codigo", codigo);
  //console.log('Documento a Buscar: '+codigo)
  //f.append("METHOD", "PUT");
  //await (axios.post(baseUrl, f, {params: {codigo: codigo}})
  .then( response=>{ 
         console.log('Entre: '+codigo)
         data1 = response.data;
         const xt =data1.clave;
         console.log('Clave en dba: '+xt)
         console.log(data1)
         if(magicword === xt){
            const idempleado = data1.id_empleado;
            const nombreempleado = data1.Nombre;
            const estado = data1.estado;
            const tipoEmpleado= data1.claseempleado;
             console.log(data1)
             console.log(data1.claseempleado)
             console.log('el nombre estado es '+estado)
             login(idempleado,nombreempleado,estado,tipoEmpleado);
             console.log('pasar');
             navigate('/private')
            } else{
                 Swal.fire({
                 title: 'Error!',
                 text: 'El Documento o la Clave estan erradas',
                 icon: 'error',
                 confirmButtonText: 'OK'
                })
          }
        })
    .catch(error=>{
      console.log(error);
    })
  }
   
  function ingresar(){
      peticionGet();
     
      }
      

  return (
    <div className="containerlogin">
    <div className="screen__content">
      <Card className="login" alt='Logo'>
        <img className="card-img-top" src={imagensinsisalud} alt='Logo'/>
        <p>Bienvenido a Nuestra Oficina Virtual</p>    
        <div className="login__field">
          <i className="login__icon fas fa-user" />
          <input type="text" className="login__input" placeholder="Doc. de Identidad" value={codigo} onChange={handleSumiChangeCodigo}/>
        </div>
        <div className="login__field">
          <i className="login__icon fas fa-lock" />
          <input type="password" className="login__input" placeholder="Password" value={magicword} onChange={handleSumiChange}/>
        </div>
        <Button variant="primary" onClick={ingresar}>
            Ingresar
          </Button>	
      </Card>
    </div>
</div>

  )
}

export default Login