import React from 'react'
import { useAuthcontes} from "../contexts/Authcontes";
import { useNavigate } from 'react-router-dom' 
import { useState } from 'react';
import './login.css';
import axios from 'axios';
import { Card,Button } from 'react-bootstrap';
import imagensinsisalud from './images/Imagen1.png';
const Swal = require('sweetalert2')
const baseUrl="https://soul-group.net/res_prueba/login.php?codigo="



const Cambioclave = () => {
  const navigate = useNavigate()
const {login}  = useAuthcontes();
const [claveactual,SetActual] = useState('');
const [claveanterior,SetAnterior] = useState('');
const [clavenueva,SetNueva] = useState('');
const [claveok,SetOk] = useState('');
const [codigo,Setcodigo] = useState('');
const [data, setData]=useState([]);
const {idempleado}  = useAuthcontes();

function handleSumiChangeActual(event) {
      SetNueva(event.target.value);
  }

function handleSumiChangeAnterior(event) {
    SetAnterior(event.target.value);
}

function handleSumiChangeOk(event) {
  SetOk(event.target.value);
}


const peticionPut=async()=>{
  //const baseUrl="https://soul-group.net/res_prueba/login.php?id_empleado="+String(idempleado);
  const baseUrl="https://soul-group.net/res_prueba/login2.php"
  console.log(baseUrl);
  console.log('la ruta');
  console.log(claveok);
  var f = new FormData();
  f.append("clave", claveok);
  f.append("METHOD", "PUT");
    await axios.post(baseUrl, f, {params: {id_empleado: idempleado}})
  .then(response=>{
    console.log('Cambie Clave');
    console.log(response);
    var dataNueva= data;
    Swal.fire({
      title: 'Ok',
      text: 'Tu clave ha sido modificada',
      icon: 'success',
      confirmButtonText: 'OK'
    })
    navigate('/private')
  }).catch(error=>{
    console.log(error);
  })
}

 
   
  function actualizar(){
     if (claveok === ''){Swal.fire({
      title: 'Error!',
      text: 'Debes colocar una clave',
      icon: 'error',
      confirmButtonText: 'OK'
    })}else {
      if(claveanterior === claveok){
        peticionPut();
      } else{
        Swal.fire({
          title: 'Error!',
          text: 'La Confirmacion de la clave no corresponde',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }}
     
      }
      

  return (
    <div className="containerlogin">
    <div className="screen__content">
      <Card className="login" >
        <img className="card-img-top" src={imagensinsisalud}/>    
        <div className="login__field">
          <i className="login__icon fas fa-lock" />
          <input type="password" className="login__input" placeholder="Nueva Clave" value={claveanterior} onChange={handleSumiChangeAnterior}/>
        </div>
        <div className="login__field">
          <i className="login__icon fas fa-lock" />
          <input type="password" className="login__input" placeholder="Confirmar Clave" value={claveok} onChange={handleSumiChangeOk}/>
        </div>
        <Button variant="primary" onClick={actualizar}>
            ACTUALIZAR
          </Button>	
      </Card>
    </div>
</div>

  )
}

export default Cambioclave